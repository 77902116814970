function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

//return the Date in the format expected by the server: YYYY-MM-DD
function getDateInServerExpectedFormat(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
}

function formatDate(dateTimeString) {
  // Extract the date part from the ISO 8601 datetime string
  const datePart = dateTimeString.split("T")[0];

  return datePart;
}

const dateAfter = (date) => {
  let tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

function preparePhotosForUpload(photos) {
  const formData = new FormData();
  photos.forEach((file) => {
    formData.append("images[]", file);
  });
  return formData;
}

const truncateName = (name, maxLength = 13) => {
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + "...";
  }
  return name;
};

// Function to parse the double value
const parseCurrencyToDouble = (currencyStr) => {
  // Remove non-numeric characters (except for the decimal point)
  const numericString = currencyStr.replace(/[^\d.-]+/g, "");
  return parseFloat(numericString);
};

export {
  capitalize,
  getDateInServerExpectedFormat,
  formatDate,
  preparePhotosForUpload,
  dateAfter,
  truncateName,
  parseCurrencyToDouble,
};
