import { createContext, useContext, useState } from "react";
import { validateToken, signOut } from "../services/authService";
import { useQuery } from "@tanstack/react-query";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({
    isAuthenticated: false,
    isLoadingUser: true,
    data: null,
  });


  useQuery(["validateToken"], validateToken, {
    retry: false,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      if (data.data.success) {
        setCurrentUser({
          ...currentUser,
          isAuthenticated: true,
          isLoadingUser: false,
          data: data.data.data,
        });
      }
    },
    onError: (error) => {
      setCurrentUser({
        ...currentUser,
        isLoadingUser: false,
      });
    },
  });

  const login = (userData) => {
    setCurrentUser({
      ...currentUser,
      data: userData,
      isAuthenticated: true,
    });
  };

  const logout = () => {
    signOut().then((data) => {
      setCurrentUser({ ...currentUser, data: null, isAuthenticated: false });
      window.location.replace("/");
    });
  };

  const contextValue = {
    login,
    logout,
    isAuthenticated: currentUser.isAuthenticated,
    currentUser: currentUser.data,
    isLoadingUser: currentUser.isLoadingUser,
    setCurrentUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
