import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepTitle,
  Stepper,
  StepStatus,
  useSteps,
  Flex,
  Spinner,
  Text,
  useBreakpointValue,
  Show,
} from "@chakra-ui/react";
import BookingSummaryStep from "./steps/BookingSummaryStep";
import BookingConfirmationStep from "./steps/BookingConfirmationStep";
import UserDataStep from "./steps/UserDataStep";
import { useMatch } from "react-router-dom";
import { getBooking } from "../../services/bookings";
import { useQuery } from "@tanstack/react-query";
import { ACCOMMODATION_TYPES } from "../../constants";

function StepsLayout({ nextStep, prevStep, activeStep, children }) {
  return (
    <Flex
      justifyContent="space-between"
      direction={["column", "column", "row"]}
    >
      <Box pt="24px" pr={[0, 0, "12px"]} flex="1 1 0">
        {children[0]}
      </Box>
      {activeStep === 1 && (
        <Box pt="24px" pl={[0, 0, "12px"]} flex="2 1 0">
          {children[1]}
        </Box>
      )}
      {activeStep === 2 && (
        <Box pt="24px" pl={[0, 0, "12px"]} flex="2 1 0">
          {children[2]}
        </Box>
      )}
    </Flex>
  );
}

function BookingStepper() {
  const match = useMatch("/book/:bookingId");
  const bookingId = match?.params.bookingId;

  const { isLoading, data } = useQuery(
    ["booking", bookingId],
    () => getBooking(bookingId),
    {
      select: (data) => data.data,
      onSuccess: (data) => {},
      onError: (error) => {},
    },
    {
      enabled: true,
    }
  );

  const steps = [
    {
      title: "Your choice",
      description: data
        ? data.bookingType === "stay"
          ? "Accommodation"
          : data.bookingType === "car"
          ? "Car"
          : "Tour"
        : "Booking",
    },
    { title: "Your data", description: "Contact Info" },
    { title: "Last step", description: "Complete Reservation" },
  ];

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 1,
    count: steps.length,
  });

  const stepsProps = {
    nextStep: goToNext,
    prevStep: goToPrevious,
    activeStep,
  };

  const stepStatusProps = useBreakpointValue(
    {
      md: {
        incomplete: <StepNumber />,
        active: <StepNumber />,
      },
      base: {},
    },
    {
      fallback: "md",
    }
  );

  if (isLoading) return <Spinner />;

  let bookingSummaryProps = {};

  // FOR STAY BOOKING TYPE
  if (data?.bookingType === "stay") {
    bookingSummaryProps = {
      accommodation: data?.accommodation,
      details: {
        checkIn: new Date(data?.checkInDate),
        checkOut: new Date(data?.checkOutDate),
        totalNights: data?.totalStayLengthInDays,
        adults: data?.adults,
        children: data?.children,
        priceSummary: {
          entries: data?.accommodationUnits.map((unit) => ({
            name: unit.name,
            startDate: unit.startDate,
            endDate: unit.endDate,
            totalPricePerUnit: unit.totalPrice,
          })),
          totalPrice: data?.fullPrice,
        },
      },
      accommodationUnits: data?.accommodationUnits,
    };
  }
  // FOR CAR BOOKING TYPE
  else if (data?.bookingType === "car") {
    bookingSummaryProps = {
      car: data?.car,
      details: {
        checkIn: new Date(data?.checkInDate),
        checkOut: new Date(data?.checkOutDate),
        pickupTime: data?.pickupTime,
        dropoffTime: data?.dropoffTime,
        totalNights: data?.totalStayLengthInDays,
        adults: data?.adults,
        children: data?.children,
        priceSummary: {
          entries: [],
          totalPrice: data?.fullPrice,
        },
      },
    };
  }
  // FOR TOUR BOOKING TYPE
  else if (data?.bookingType === "tour") {
    bookingSummaryProps = {
      tour: data?.tour,
      details: {
        checkIn: new Date(data?.checkInDate),
        checkOut: new Date(data?.checkOutDate),
        totalNights: data?.totalStayLengthInDays,
        adults: data?.adults,
        children: data?.children,
        priceSummary: {
          entries: data?.tour.accommodationUnits.map((unit) => ({
            //name: unit.stay.name,
            name:
              unit.stay.type === ACCOMMODATION_TYPES.HOTEL
                ? `${unit.name} (${unit.stay.name})`
                : `${unit.name}`,
            startDate: unit.startDate,
            endDate: unit.endDate,
            totalPricePerUnit: unit.totalPrice,
          })),
          //tax: 5,
          vehicleCategory: data?.tour.selectedVehicleCategory,
          //driverPrice: data?.driverPrice,
          pickupLocation: data?.tour.pickupLocation,
          totalPrice: data?.fullPrice,
        },
      },
      accommodationUnits: data?.tour.accommodationUnits,
    };
  }

  bookingSummaryProps["bookingType"] = data?.bookingType;

  const stepsComponents = [
    <BookingSummaryStep {...bookingSummaryProps} />,
    <UserDataStep
      {...stepsProps}
      startDate={bookingSummaryProps.details.checkIn}
      bookingId={data?.id}
    />,
    <BookingConfirmationStep {...stepsProps} totalPrice={data?.fullPrice} />,
  ];

  return (
    <>
      <Stepper index={activeStep} orientation="horizontal" gap="0">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} {...stepStatusProps} />
            </StepIndicator>
            <Show above="md">
              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>
            </Show>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Show below="md">
        <Text mt={2}>
          Step {activeStep + 1}: <b>{steps[activeStep]?.description}</b>
        </Text>
      </Show>
      <StepsLayout {...stepsProps}>{stepsComponents}</StepsLayout>
    </>
  );
}

export default BookingStepper;
