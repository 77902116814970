import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
  Link,
  Divider,
} from "@chakra-ui/react";
import Checkout from "../../payment/Checkout";
import { useState } from "react";
import { parseCurrencyToDouble } from "../../../utils/helpers";
import { CURRENCY_SYMBOLS } from "../../../constants";

function BookingConfirmationStep({
  prevStep,
  nextStep,
  activeStep,
  totalPrice,
}) {
  let qurrencySymbol = "";
  if (localStorage.getItem("currencyCode") === null)
    qurrencySymbol = CURRENCY_SYMBOLS["eu"];

  for (const code in CURRENCY_SYMBOLS) {
    if (code === localStorage.getItem("currencyCode")) {
      qurrencySymbol = CURRENCY_SYMBOLS[code];
      break;
    }
  }
  const twentyPercent = parseCurrencyToDouble(totalPrice) * (20 / 100);
  const totalPriceDouble = parseCurrencyToDouble(totalPrice);
  const [showPrevButton, setShowPrevButton] = useState(true);
  const showPreviousButton = (isVisible) => {
    setShowPrevButton(isVisible);
  };
  return (
    <Box border="1px" borderColor="gray.200" borderRadius="lg" padding="15px">
      <VStack align="flex-start" spacing="2">
        <Heading as="h3" size="sm">
          Finish reservation
        </Heading>
        <Text fontSize="xs">
          You will make an advance payment of 20% of the total amount now. The
          remaining balance will be paid upon arrival.
        </Text>
        <VStack align="flex-start" spacing="2">
          <Text fontSize="sm">
            Total amount: <strong>{totalPrice}</strong>
          </Text>
          <Text fontSize="sm">
            Advance payment:{" "}
            <strong>{`${qurrencySymbol} ${twentyPercent.toFixed(2)}`}</strong>
          </Text>
          <Text fontSize="sm">
            Rest to pay upon arrival:{" "}
            <strong>{`${qurrencySymbol} ${(
              totalPriceDouble - twentyPercent
            ).toFixed(2)}`}</strong>
          </Text>
          <Divider />
          <Text fontSize="sm">
            Due now:{" "}
            <strong>{`${qurrencySymbol} ${twentyPercent.toFixed(2)}`}</strong>
          </Text>
        </VStack>
        <Checkout
          showPreviousButton={showPreviousButton}
          nextStep={nextStep}
          activeStep={activeStep}
        />
        {
          showPrevButton ? (
            <VStack align="flex-start" spacing="2">
              <Text fontSize="2xs" color={"grey"}>
                *By proceeding with payment you agree with our{" "}
                <Link
                  textDecor={"underline"}
                  href="https://fursatook.com/terms_of_use"
                  isExternal
                >
                  Terms of use
                </Link>{" "}
                and{" "}
                <Link
                  textDecor={"underline"}
                  href="https://fursatook.com/privacy_policy"
                  isExternal
                >
                  Privacy policy
                </Link>
              </Text>
              <Button colorScheme="blue" size="sm" onClick={prevStep}>
                Previous
              </Button>
            </VStack>
          ) : null /*
          <Button fontSize="xs">Go to my reservations</Button>
        */
        }
      </VStack>
    </Box>
  );
}

export default BookingConfirmationStep;
