import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
  Flex,
  Link,
  useToast,
  Stack,
  FormControl,
  Input,
  FormErrorMessage,
  InputGroup,
  Divider,
  HStack,
  CloseButton,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { useAuth } from "../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { updateUser } from "../../../services/authService";
import { ROLES } from "../../../constants";
import { useDropZoneUpload } from "../../../utils/customHooks";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useState } from "react";
import { submitPhotosToBooking } from "../../../services/bookings";
import { usePhotoUpload } from "../../../utils/sharedMutations";
import {
  getDateInServerExpectedFormat,
  preparePhotosForUpload,
} from "../../../utils/helpers";
import { useMatch } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { phoneNumberInputStyleInherit } from "../../../styles/phoneNumberInputStyleInherit";
import "./UserDataStep.scss";
import { upperCase } from "lodash";
import pdfPreview from "../../../assets/pdf_preview_icon.png";
import { truncateName } from "../../../utils/helpers";

function UserDataStep({ nextStep, startDate, bookingId }) {
  const { currentUser, isAuthenticated, isLoadingUser, setCurrentUser } =
    useAuth();
  const { t } = useTranslation("common");
  const toast = useToast();
  const [isPhoneInputFocused, setIsPhoneInputFocused] = useState(false);

  const match = useMatch("/book/:bookingUuid");
  const bookingUuid = match?.params.bookingUuid;

  const updateUserMutation = useMutation({
    mutationFn: (userData) => {
      if (currentUser?.type === ROLES.VISITOR)
        return updateUser({
          ...userData,
          telephoneNumber: userData.telephoneNumber,
          passportExpiresAt: getDateInServerExpectedFormat(passportExpiryDate),
        });
    },
    onError: (error) => {
      toast({
        title: "Invalid email! Please try again.",
        status: "error",
      });
    },
    onSuccess: (data) => {
      toast({
        title: "Your details have been saved!",
        status: "success",
      });
      setCurrentUser(data.data);
    },
  });

  const reservationStartDatePlus3Months = () => {
    let reservationStartDate = new Date(startDate);
    reservationStartDate.setMonth(reservationStartDate.getMonth() + 3);
    return reservationStartDate;
  };

  const [passportExpiryDate, setPassportExpiryDate] = useState(
    reservationStartDatePlus3Months()
  );

  const { selectedFiles, getRootProps, getInputProps, handleRemoveFile } =
    useDropZoneUpload([], () => setShowPassportPhotoErrorMessage(false));

  const {
    selectedFiles: selectedFiles2,
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    handleRemoveFile: handleRemoveFile2,
  } = useDropZoneUpload([], () => setShowPhotosErrorMessage(false));

  const submitPhotosMutation = usePhotoUpload(
    ({ bookingId, photos, reservationPassport }) =>
      submitPhotosToBooking(bookingId, photos, reservationPassport)
  );

  const initialFormValues =
    isLoadingUser || !currentUser
      ? {
          firstName: "",
          lastName: "",
          email: "",
          telephoneNumber: "",
          nameOnPassport: "",
        }
      : {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          telephoneNumber:
            currentUser !== null && currentUser.telephoneNumber !== null
              ? `${currentUser.telephoneNumber}`
              : "",
          nameOnPassport: `${currentUser.firstName} ${currentUser.lastName}`,
        };

  const [showPassportPhotoErrorMessage, setShowPassportPhotoErrorMessage] =
    useState(false);
  const [showPhotosErrorMessage, setShowPhotosErrorMessage] = useState(false);

  const onSubmit = async (values, photos1, photos2) => {
    await updateUserMutation.mutateAsync(values);

    if (photos1.length > 0) {
      await submitPhotosMutation.mutateAsync({
        bookingId: bookingId,
        photos: preparePhotosForUpload(photos1),
        reservationPassport: true,
      });
    }

    if (photos2.length > 0) {
      await submitPhotosMutation.mutateAsync({
        bookingId: bookingId,
        photos: preparePhotosForUpload(photos2),
        reservationPassport: false,
      });
    }

    nextStep();
  };

  if (isLoadingUser) {
    return <Spinner />;
  }

  return (
    <VStack width="full" gap="5" alignItems="flex-start">
      {((!isLoadingUser && !isAuthenticated) ||
        currentUser?.type === ROLES.VISITOR) && (
        <Box
          width="full"
          border="1px"
          borderColor="gray.200"
          borderRadius="lg"
          padding="15px"
        >
          <Flex gap="5" alignItems="center">
            <FontAwesomeIcon icon={faUser} />
            <Text fontSize="sm">
              You are not signed in.{" "}
              <Link
                color="blue.500"
                href={`/login?returnUrl=${encodeURIComponent(
                  `/book/${bookingUuid}`
                )}`}
              >
                Sign in
              </Link>{" "}
              to book with your saved details or
              <Link
                color="blue.500"
                href={`/register?returnUrl=${encodeURIComponent(
                  `/book/${bookingUuid}`
                )}`}
              >
                {" "}
                register
              </Link>{" "}
              to manage your bookings on the go!
            </Text>
          </Flex>
        </Box>
      )}

      <Box
        width="full"
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        padding="15px"
      >
        <VStack align="flex-start" spacing="2">
          <Heading as="h3" size="sm" mb="15px">
            Your details
          </Heading>
          <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
              firstName: Yup.string().required(t("requiredFieldMessage")),
              lastName: Yup.string().required(t("requiredFieldMessage")),
              email: Yup.string()
                .email(t("invalidEmailMessage"))
                .required(t("requiredFieldMessage")),
              telephoneNumber: Yup.string()
                .required(t("requiredFieldMessage"))
                .min(8, "Phone number is too short"),
              nameOnPassport: Yup.string().required(t("requiredFieldMessage")),
            })}
            onSubmit={(values) => {
              const newlyAddedFiles = selectedFiles
                .filter((file) => !file.id)
                .map((file) => file.file);

              const newlyAddedFiles2 = selectedFiles2
                .filter((file) => !file.id)
                .map((file) => file.file);

              if (newlyAddedFiles.length < 1) {
                setShowPassportPhotoErrorMessage(true);
                return;
              }

              if (newlyAddedFiles2.length < 1) {
                setShowPhotosErrorMessage(true);
                return;
              }

              onSubmit(values, newlyAddedFiles, newlyAddedFiles2);
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  <Flex gap="3">
                    <Field name="firstName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.firstName && form.touched.firstName
                          }
                        >
                          <Text mb="6px" fontSize={"sm"}>
                            {t("firstNameInputPlaceholder")}
                          </Text>
                          <Input {...field} variant="outline" />
                          <FormErrorMessage>
                            {form.errors.firstName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="lastName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.lastName && form.touched.lastName
                          }
                        >
                          <Text mb="6px" fontSize={"sm"}>
                            {t("lastNameInputPlaceholder")}
                          </Text>
                          <Input {...field} variant="outline" />
                          <FormErrorMessage>
                            {form.errors.lastName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <Text mb="6px" fontSize={"sm"}>
                          {t("emailInputPlaceholder")}
                        </Text>
                        <Input {...field} variant="outline" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="telephoneNumber">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.telephoneNumber &&
                          form.touched.telephoneNumber
                        }
                      >
                        <Text mb="6px" fontSize={"sm"}>
                          {t("phoneNumberInputPlaceholder")}
                        </Text>
                        <InputGroup>
                          <PhoneInput
                            as="input"
                            style={{
                              ...phoneNumberInputStyleInherit,
                              ...(isPhoneInputFocused
                                ? {
                                    borderColor: "#3182ce",
                                    boxShadow: "0 0 0 1px #3182ce",
                                  }
                                : {}),
                              ...(form.errors.telephoneNumber
                                ? {
                                    borderColor: "#E53E3E",
                                    boxShadow: "0 0 0 1px #E53E3E",
                                  }
                                : {}),
                            }}
                            onFocus={() => setIsPhoneInputFocused(true)}
                            onBlur={() => setIsPhoneInputFocused(false)}
                            defaultCountry={upperCase(
                              localStorage.getItem("currencyCode") === "eu"
                                ? ""
                                : localStorage.getItem("currencyCode")
                            )}
                            placeholder=""
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue("telephoneNumber", value);
                            }}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.telephoneNumber}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Divider />

                  <Flex width="full" direction="column" gap={5}>
                    <Heading as="h3" size="sm">
                      Documents
                    </Heading>
                    <div>
                      <Text mb="6px" fontSize={"sm"}>
                        Please upload copy of your passport (guest on which name
                        is reservation made)
                      </Text>
                      <div
                        {...getRootProps()}
                        style={{ padding: "20px", border: "2px dashed #ccc" }}
                      >
                        <input {...getInputProps()} />
                        <p>{t("dropZoneMessage")}</p>
                      </div>

                      <Box mt={selectedFiles.length > 0 ? 2 : 0}>
                        <HStack wrap="wrap" spacing="4">
                          {selectedFiles.map((image, index) => {
                            if (image._destroy) {
                              return null;
                            }
                            return (
                              <Box key={index}>
                                <Box
                                  width="50px"
                                  height="50px"
                                  position={"relative"}
                                >
                                  <VStack>
                                    <Image
                                      maxWidth="50px"
                                      maxHeight="50px"
                                      src={
                                        image.fileType === "pdf"
                                          ? pdfPreview
                                          : image.url
                                      }
                                    />
                                    <Text isTruncated fontSize="10px">
                                      {truncateName(image.name)}
                                    </Text>
                                  </VStack>
                                  <CloseButton
                                    size={"sm"}
                                    position={"absolute"}
                                    top={"-10px"}
                                    right={"-10px"}
                                    onClick={() =>
                                      handleRemoveFile(index, image.id)
                                    }
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                        </HStack>
                      </Box>
                      <Text
                        display={
                          showPassportPhotoErrorMessage ? "block" : "none"
                        }
                        mt={1}
                        fontSize="sm"
                        color="red.500"
                      >
                        {"Passport photo is required"}
                      </Text>
                      <br />
                      <Text mb="6px" fontSize={"sm"}>
                        Please upload copy of your plane ticket
                      </Text>
                      <div
                        id="guest_documents_dropzone"
                        {...getRootProps2()}
                        style={{
                          padding: "20px",
                          border: "2px dashed #ccc",
                        }}
                      >
                        <input {...getInputProps2()} />
                        <p>{t("dropZoneMessage")}</p>
                      </div>
                      <Box mt={selectedFiles2.length > 0 ? 2 : 0}>
                        <HStack wrap="wrap" spacing="4">
                          {selectedFiles2.map((image, index) => {
                            if (image._destroy) {
                              return null;
                            }
                            return (
                              <Box key={index}>
                                <Box
                                  width="50px"
                                  height="50px"
                                  position={"relative"}
                                >
                                  <VStack>
                                    <Image
                                      maxWidth="50px"
                                      maxHeight="50px"
                                      src={
                                        image.fileType === "pdf"
                                          ? pdfPreview
                                          : image.url
                                      }
                                    />
                                    <Text isTruncated fontSize="10px">
                                      {truncateName(image.name)}
                                    </Text>
                                  </VStack>
                                  <CloseButton
                                    size={"sm"}
                                    position={"absolute"}
                                    top={"-10px"}
                                    right={"-10px"}
                                    onClick={() =>
                                      handleRemoveFile2(index, image.id)
                                    }
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                        </HStack>
                      </Box>
                      <Text
                        display={showPhotosErrorMessage ? "block" : "none"}
                        mt={1}
                        fontSize="sm"
                        color="red.500"
                      >
                        {"Photos are required"}
                      </Text>
                    </div>

                    <Box>
                      <Text mb="6px" fontSize={"sm"}>
                        Passport expiry date
                      </Text>

                      <SingleDatepicker
                        name="date-input"
                        minDate={reservationStartDatePlus3Months()}
                        date={passportExpiryDate}
                        onDateChange={setPassportExpiryDate}
                      />
                    </Box>

                    <Field name="nameOnPassport">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.nameOnPassport &&
                            form.touched.nameOnPassport
                          }
                        >
                          <Text mb="6px" fontSize={"sm"}>
                            Name on passport
                          </Text>
                          <Input {...field} variant="outline" />
                          <FormErrorMessage>
                            {form.errors.nameOnPassport}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>

                  <Button
                    colorScheme="teal"
                    type="submit"
                    variant="solid"
                    isLoading={updateUserMutation.isLoading}
                    rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
                  >
                    Final Step
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </VStack>
      </Box>
    </VStack>
  );
}

export default UserDataStep;
