import { React, useState } from "react";
import {
  Stack,
  Input,
  InputGroup,
  Button,
  Center,
  Heading,
  useToast,
  FormControl,
  FormErrorMessage,
  Spinner,
  InputRightElement,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { register, updateUser } from "../../services/authService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ROLES } from "../../constants";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { upperCase } from "lodash";
import "../../styles/phoneNumberInput.scss";
import { phoneNumberInputStyles } from "../../styles/phoneNumberInputStyles";
import "./Register.scss";

function Register() {
  const { t } = useTranslation("common");
  const toast = useToast();
  const navigate = useNavigate();
  const { login, currentUser, isLoadingUser } = useAuth();
  const [isPhoneInputFocused, setIsPhoneInputFocused] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const searchParams = new URLSearchParams(window.location.search);

  const mutation = useMutation({
    mutationFn: (userData) => {
      return currentUser?.type === ROLES.VISITOR
        ? updateUser({
            ...userData,
            telephoneNumber: userData.telephoneNumber,
            type: ROLES.GUEST,
          })
        : register({
            ...userData,
            telephoneNumber: userData.telephoneNumber,
          });
    },
    onError: (error) => {
      // toast({
      //   title: "greska" + error.message,
      //   status: "error",
      // });
    },
    onSuccess: (data) => {
      let {
        id,
        email,
        firstName,
        lastName,
        type,
        emailConfirmed,
        telephoneNumber,
      } = data.data.data;
      login({
        id,
        email,
        firstName,
        lastName,
        type,
        emailConfirmed,
        telephoneNumber,
      });
      toast({
        title: t("accountRegistrationSuccessMessage"),
        status: "success",
      });
      navigate(decodeURIComponent(searchParams.get("returnUrl") || "/"));
    },
  });

  if (isLoadingUser) {
    return <Spinner size="xs" />;
  }

  const initialFormValues =
    isLoadingUser || !currentUser || currentUser.type === ROLES.VISITOR
      ? {
          firstName: "",
          lastName: "",
          email: "",
          telephoneNumber: searchParams.get("telephone_number") || "",
        }
      : {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          telephoneNumber: currentUser.telephoneNumber,
        };

  return (
    <Center>
      <Stack spacing={3} width={"400px"}>
        <Heading size={"lg"} mb={8}>
          {t("registerHeading")}
        </Heading>
        <Formik
          initialValues={{
            ...initialFormValues,
            password: "",
            passwordConfirmation: "",
            address: "",
            type: searchParams.get("user_type") || ROLES.GUEST,
            confirmSuccessUrl: document.referrer || document.location.origin,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t("requiredFieldMessage")),
            lastName: Yup.string().required(t("requiredFieldMessage")),
            email: Yup.string()
              .email(t("invalidEmailMessage"))
              .required(t("requiredFieldMessage")),
            password: Yup.string()
              .required(t("requiredFieldMessage"))
              .min(6, t("passwordTooShortMessage")),
            passwordConfirmation: Yup.string()
              .required(t("requiredFieldMessage"))
              .min(6, t("passwordTooShortMessage"))
              .oneOf(
                [Yup.ref("password"), null],
                t("passwordsDoNotMatchMessage")
              ),
            telephoneNumber: Yup.string()
              .required(t("requiredFieldMessage"))
              .min(8, "Phone number is too short"),

            address: Yup.string().required(t("requiredFieldMessage")),
          })}
          onSubmit={(values) => {
            mutation.mutate(values);
          }}
        >
          {(props) => (
            <Form>
              <Stack spacing={3}>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <Input
                        {...field}
                        placeholder={t("emailInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => {
                    return (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <InputGroup size="md">
                          <Input
                            {...field}
                            type={show ? "text" : "password"}
                            //type="password"
                            placeholder={t("passwordInputPlaceholder")}
                            variant="filled"
                          />
                          <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={handleClick}>
                              {show ? "Hide" : "Show"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="passwordConfirmation">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.passwordConfirmation &&
                        form.touched.passwordConfirmation
                      }
                    >
                      <InputGroup size="md">
                        <Input
                          {...field}
                          type={show ? "text" : "password"}
                          //type="password"
                          placeholder={t(
                            "passwordConfirmationInputPlaceholder"
                          )}
                          variant="filled"
                        />
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.passwordConfirmation}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="telephoneNumber">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.telephoneNumber &&
                        form.touched.telephoneNumber
                      }
                    >
                      <InputGroup>
                        <PhoneInput
                          value={
                            initialFormValues.telephoneNumber
                              ? `+${initialFormValues.telephoneNumber}`
                              : ""
                          }
                          style={{
                            ...phoneNumberInputStyles,
                            ...(isPhoneInputFocused &&
                            !form.errors.telephoneNumber
                              ? {
                                  borderColor: "#3182ce",
                                  //boxShadow: "0 0 0 1px #3182ce",
                                }
                              : {}),
                            ...(form.errors.telephoneNumber
                              ? {
                                  borderColor: "#E53E3E",
                                  //boxShadow: "0 0 0 1px #E53E3E",
                                }
                              : {}),
                          }}
                          onFocus={() => setIsPhoneInputFocused(true)}
                          onBlur={() => setIsPhoneInputFocused(false)}
                          defaultCountry={upperCase(
                            localStorage.getItem("currencyCode") === "eu"
                              ? ""
                              : localStorage.getItem("currencyCode")
                          )}
                          placeholder={t("phoneNumberInputPlaceholder")}
                          onChange={(value) => {
                            form.setFieldValue("telephoneNumber", value);
                          }}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.telephoneNumber}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="address">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.address && form.touched.address}
                    >
                      <Input
                        {...field}
                        placeholder={t("addressInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="firstName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.firstName && form.touched.firstName
                      }
                    >
                      <Input
                        {...field}
                        placeholder={t("firstNameInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>
                        {form.errors.firstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.lastName && form.touched.lastName}
                    >
                      <Input
                        {...field}
                        placeholder={t("lastNameInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>
                        {form.errors.lastName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  width="full"
                  isLoading={mutation.isLoading}
                  type="submit"
                  colorScheme="whatsapp"
                  mt={4}
                >
                  {t("register")}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Center>
  );
}

export default Register;
